import React from 'react'
import './video.css'

export default function Video() {
  return (
    <>
    <div className='higherTopper'>
      <div className='newsmallHeading'><h1>OUR VIDEOS</h1></div>
        <div className='mainTopperDiv'>
          <div className='BigHeading'><h1>OUR TOPPERS</h1></div>
          <div className='smallHeading'><h1>OUR VIDEOS</h1></div>
        </div>
      </div>
      <h4 className='videolatestheading'>Our learning and growth have been encapsulated in this repertoire of videos</h4>
      <div className='mainVideoDiv'>
        <div className="youtubeDiv">
          {/* <iframe  src="https://www.youtube.com/embed/0inWIV9vR3k?si=RCMEYZDJy-ZfKdZ3&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
          <iframe className='rectangleImg' src="https://www.youtube.com/embed/AtgFnLCMXaM?si=8nZu8nSyWlfgETS9&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div className="youtubeDiv">
          <iframe className='rectangleImg' src="https://www.youtube.com/embed/wZoTjzHZ0RE?si=-JGRvirFlAkbwFkg&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
        <div className="youtubeDiv">
          {/* first video */}
          {/* <iframe className='rectangleImg' src="https://www.youtube.com/embed/0inWIV9vR3k?si=RCMEYZDJy-ZfKdZ3&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}

          <iframe className='rectangleImg' src="https://www.youtube.com/embed/r9MgD3XpoDU?si=uE4iKvFL3utVy7YG&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
    </>
  )
}
