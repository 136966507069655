// import Navbar from "./components/Navbar2";
import Navbar from "./Components/Navbar2";
import NewNav from "./Components/Navbar/Navbar"
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Example from "./Components/delete/Example";
import Example2 from "./Components/delete/Example2";
import img from '../src/Assets/Navbar/Nayaks_logo.png'
import HomeCon from "./Components/HomeMain/HomeCon";
import Pageroute from "./Route/Route";

const App = () => { 
  return (
    <>
    <Pageroute />
      {/* <BrowserRouter>
        <header>
          <div className="nav-area" >
            <a href="/#" className="mainLogo"><img src={img}></img> </a>
            <Navbar />
          </div>
          <div className="my-area">
            <NewNav />
          </div>
        </header>
        <HomeCon />

        <Routes>  
        </Routes>
      </BrowserRouter> */}
    </>
  );
};

export default App;