import React, { useState } from 'react'
import math1 from '../../icsedoc/X IC MATH PRAC PAPER 1.pdf'
import math2 from '../../icsedoc/X IC MATH PRAC PAPER 2.pdf'
import math3 from '../../icsedoc/X IC MATH PRAC PAPER 3.pdf'
import eng1 from '../../icsedoc/X IC ENG 1 PRAC PAPER 1.pdf'
import eng2 from '../../icsedoc/X IC ENG 1 PRAC PAPER 2.pdf'
import eng3 from '../../icsedoc/X IC ENG 1 PRAC PAPER 3.pdf'
import che1 from '../../icsedoc/X IC CHEM PRAC PAPER 1.pdf'
import che2 from '../../icsedoc/X IC CHEM PRAC PAPER 2.pdf'
import che3 from '../../icsedoc/X IC CHEM PRAC PAPER 3.pdf'
import bio1 from '../../icsedoc/X IC BIO PRAC PAPER 1.pdf'
import bio2 from '../../icsedoc/X IC BIO PRAC PAPER 2.pdf'
import bio3 from '../../icsedoc/X IC BIO PRAC PAPER 3.pdf'
import phy1 from '../../icsedoc/X IC PHY PRAC PAPER 1.pdf'
import phy2 from '../../icsedoc/X IC PHY PRAC PAPER 2.pdf'
import phy3 from '../../icsedoc/X IC PHY PRAC PAPER 3.pdf'
import his1 from '../../icsedoc/X IC HIST PRAC PAPER 1.pdf'
import his2 from '../../icsedoc/X IC HIST PRAC PAPER 2.pdf'
import his3 from '../../icsedoc/X IC HIST PRAC PAPER 3.pdf'
import geog1 from '../../icsedoc/X IC GEOG PRAC PAPER 1.pdf'
import geog2 from '../../icsedoc/X IC GEOG PRAC PAPER 2.pdf'
import geog3 from '../../icsedoc/X IC GEOG PRAC PAPER 3.pdf'
import engIc1 from '../../icsedoc/X IC ENG 2 PRAC PAPER 1.pdf'
import engIc2 from '../../icsedoc/X IC ENG 2 PRAC PAPER 2.pdf'
import engIc3 from '../../icsedoc/X IC ENG 2 PRAC PAPER 3.pdf'

import has1 from '../../icsedoc/X-ICSE-ENG 1-HAS-80M-OCTOBER-2024.pdf'
import has2 from '../../icsedoc/X-ICSE-HINDI-HAS-80M-OCTOBER-2024.pdf'
import has3 from '../../icsedoc/X-ICSE-MAR -HAS - 80 M - OCTOBER- 2024.pdf'

import has4 from '../../icsedoc/X-ICSE-HIS & CIV-HAS - 80 M - OCTOBER- 2024.pdf'
import has5 from '../../icsedoc/X-ICSE-ENG 2-HAS-80M-OCTOBER-2024.pdf'
import has6 from '../../icsedoc/X-ICSE-GEOG-HAS - 80 M - OCTOBER- 2024.pdf'

export default function Icsepaper() {
    const [data, setData] = useState([
        // { "id": 1, "heading": "X ICSE MATH PRACTICE PAPER 1", "pdf": math1 },
        // { "id": 2, "heading": "X ICSE MATH PRACTICE PAPER 2", "pdf": math2 },
        // { "id": 3, "heading": "X ICSE MATH PRACTICE PAPER 3", "pdf": math3 },
        // { "id": 4, "heading": "X ICSE ENGLISH PRACTICE PAPER 1", "pdf": eng1 },
        // { "id": 5, "heading": "X ICSE ENGLISH PRACTICE PAPER 2", "pdf": eng2 },
        // { "id": 6, "heading": "X ICSE ENGLISH PRACTICE PAPER 3", "pdf": eng3 },
        // { "id": 7, "heading": "X ICSE CHEMISTRY  PRACTICE PAPER 1", "pdf": che1 },
        // { "id": 8, "heading": "X ICSE CHEMISTRY PRACTICE PAPER 2", "pdf": che2 },
        // { "id": 9, "heading": "X ICSE CHEMISTRY PRACTICE PAPER 3", "pdf": che3 },
        // { "id": 10, "heading": "X ICSE BIOLOGY  PRACTICE PAPER 1", "pdf": bio1 },
        // { "id": 11, "heading": "X ICSE BIOLOGY PRACTICE PAPER 2", "pdf": bio2 },
        // { "id": 12, "heading": "X ICSE BIOLOGY PRACTICE PAPER 3", "pdf": bio3 },
        // { "id": 13, "heading": "X ICSE PHYSICS  PRACTICE PAPER 1", "pdf": phy1 },
        // { "id": 14, "heading": "X ICSE PHYSICS PRACTICE PAPER 2", "pdf": phy2 },
        // { "id": 15, "heading": "X ICSE PHYSICS PRACTICE PAPER 3", "pdf": phy3 },
        // { "id": 16, "heading": "X ICSE HISTORY  PRACTICE PAPER 1", "pdf": his1 },
        // { "id": 17, "heading": "X ICSE HISTORY PRACTICE PAPER 2", "pdf": his2 },
        // { "id": 18, "heading": "X ICSE HISTORY PRACTICE PAPER 3", "pdf": his3 },
        // { "id": 19, "heading": "X ICSE GEOGRAPHY  PRACTICE PAPER 1", "pdf":geog1 },
        // { "id": 20, "heading": "X ICSE GEOGRAPHY PRACTICE PAPER 2", "pdf": geog2 },
        // { "id": 21, "heading": "X ICSE GEOGRAPHY PRACTICE PAPER 3", "pdf": geog3 },
        // { "id": 22, "heading":"X ICSE ENGLISH 2 PRACTICE PAPER 1", "pdf":engIc1},
        // { "id": 23, "heading":"X ICSE ENGLISH 2 PRACTICE PAPER 2", "pdf":engIc2},
        // { "id": 24, "heading":"X ICSE ENGLISH 2 PRACTICE PAPER 3", "pdf":engIc3},
        { "id": 25, "heading":"X ICSE ENGLISH 1 HOME ASSIGNMENT SERIES", "pdf":has1},
        { "id": 26, "heading":"X ICSE HINDI HOME ASSIGNMENT SERIES", "pdf":has2},
        { "id": 27, "heading":"X ICSE MARATHI HOME ASSIGNMENT SERIES", "pdf":has3},
        { "id": 28, "heading":"X ICSE HISTORY & CIVICS HOME ASSIGNMENT SERIES", "pdf":has4},
        { "id": 29, "heading":"X ICSE ENGLISH 2 HOME ASSIGNMENT SERIES", "pdf":has5},
        { "id": 29, "heading":"X-ICSE GEOGRAPHY HOME ASSIGNMENT SERIES", "pdf":has6}
    ])
    return (
        <>
            <div className='papermaindiv'>
                {data.map((item, index) => {
                    return (
                        <a href={item.pdf} target='_blank'>
                            <div className='papersection'>
                                <h1 className='paperheading'>{item.heading}</h1>
                            </div>
                        </a>
                    )
                })}

            </div>
        </>
    )
}
