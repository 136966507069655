import React, { useState } from 'react'
import sci1 from '../../cbsedoc/X CB SCI PRAC PAPER 1.pdf'
import sci2 from '../../cbsedoc/X CB SCI PRAC PAPER 2.pdf'
import sci3 from '../../cbsedoc/X CB SCI PRAC PAPER 3.pdf'
import sst1 from '../../cbsedoc/X CB SST PRAC PAPER 1.pdf'
import sst2 from '../../cbsedoc/X CB SST PRAC PAPER 2.pdf'
import sst3 from '../../cbsedoc/X CB SST PRAC PAPER 3.pdf'
import math1 from '../../cbsedoc/X CB MATH STD PRAC PAPER 1.pdf'
import math2 from '../../cbsedoc/X CB MATH STD PRAC PAPER 2.pdf'
import math3 from '../../cbsedoc/X CB MATH STD PRAC PAPER 3.pdf'
import eng1 from '../../cbsedoc/X CB ENG L & L PRAC PAPER 1.pdf'

export default function Cbsepaper() {
    const [data, setData] = useState([
        { "id": 1, "heading": "X CBSE SCIENCE PRACTICE PAPER 1", "pdf": sci1 },
        { "id": 2, "heading": "X CBSE SCIENCE PRACTICE PAPER 2", "pdf": sci2 },
        { "id": 3, "heading": "X CBSE SCIENCE PRACTICE PAPER 3", "pdf": sci3 },
        { "id": 4, "heading": "X CBSE SOCIAL SCIENCE PRACTICE PAPER 1", "pdf": sst1 },
        { "id": 5, "heading": "X CBSE SOCIAL SCIENCE PRACTICE PAPER 2", "pdf": sst2 },
        { "id": 6, "heading": "X CBSE SOCIAL SCIENCE PRACTICE PAPER 3", "pdf": sst3 },
        { "id": 7, "heading": "X CBSE MATH PRACTICE PAPER 1", "pdf": math1 },
        { "id": 8, "heading": "X CBSE MATH PRACTICE PAPER 2", "pdf": math2 },
        { "id": 9, "heading": "X CBSE MATH PRACTICE PAPER 3", "pdf": math3 },
        { "id": 10, "heading": "X CBSE ENGLISH L & L PRACTICE PAPER 1", "pdf": eng1 },
    ])
    return (
        <>
            <div className='papermaindiv'>
                {/* {data.map((item, index) => {
                    return (
                        <a href={item.pdf} target='_blank'>
                            <div className='papersection'>
                                <h1 className='paperheading'>{item.heading}</h1>
                            </div>
                        </a>
                    )
                })} */}

            </div>
        </>
    )
}
