import React from 'react'
import './video.css'

export default function Cbsevideo() {
    return (
        <>
            <div className='higherToppercbse'>
                {/* <div className='newsmallHeading'><h1>OUR VIDEOS cbse</h1></div>
                <div className='mainTopperDiv'>
                    <div className='BigHeading'><h1>OUR TOPPERS</h1></div>
                    <div className='smallHeading'><h1>OUR VIDEOS</h1></div>
                </div> */}
                <h1 className='testimonialHeading'>Our Videos</h1>
            </div>
            <h4 className='videolatestheading'>Our learning and growth have been encapsulated in this repertoire of videos</h4>
            <div className='mainVideoDivcbse'>
                <div className="youtubeDivcbse">
                    <iframe className='rectangleImg' src="https://www.youtube.com/embed/USEokLSIgFA?si=zSaSJ6WR0Dd7CfOS&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div className="youtubeDivcbse">
                    <iframe className='rectangleImg' src="https://www.youtube.com/embed/2q_8fQ91v-E?si=exSLI9eY50V6gFOq&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div className="youtubeDivcbse">
                    <iframe className='rectangleImg' src="https://www.youtube.com/embed/oNaX5GF-v1A?si=QCCtCG5_IueoV04b&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div className="youtubeDivcbse">
                    <iframe className='rectangleImg' src="https://www.youtube.com/embed/sYHj6uA9c6Y?si=DKLyRaD7hEoeGnbF&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div className="youtubeDivcbse">
                    <iframe className='rectangleImg' src="https://www.youtube.com/embed/32KFunrO5HI?si=b1cbaLHwFnNnjurG&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div className="youtubeDivcbse">
                <iframe className='rectangleImg' src="https://www.youtube.com/embed/GUVGCGEN_4Y?si=N_0oZ07Xo7rWvRLB&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>

            </div>
        </>
    )
}
