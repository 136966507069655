import React from 'react'
import './medical.css'
import Navbar from '../Navbar/Navbar'
import Footer from '../footer/Footer'
import mainImg from "../../Assets/faq/medical.png"
import dot from "../../Assets/faq/dot.png"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { Helmet } from "react-helmet";
import LogoContact from '../logoContact/logoContact'


export default function Medical() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>CA Coaching Classes in Thane | Nayak's Tutorials</title>
                <meta name="title" content="CA Coaching Classes in Thane | Nayak's Tutorials" />
                <meta name="description" content="Nayak's Tutorials in Thane offers CA coaching with expert guidance to help students excel in Chartered Accountancy exams. Admissions open now." />

                <link rel="canonical" href="https://nayakstutorials.com/ca/" />


                <script type="application/ld+json">
                    {
                        `"@context": "https://schema.org",
                    "@type": "LocalBusiness",
                    "name": "Nayak's Tutorials",
                    "image": "https://nayakstutorials.com/static/media/mainImg.2fd512e526d0412ced2e.png",
                    "url": "https://nayakstutorials.com/ca/",
                    "telephone": "77100 10044",
                    "address": {
                        "@type": "PostalAddress",
                    "addressLocality": "Thane",
                    "addressCountry": "IN"
  },
                    "logo": "https://nayakstutorials.com/static/media/Full_Logo.34552bea51a0af756476.png",
                    "sameAs": [
                    "https://www.facebook.com/nayakstutorialss/",
                    "https://x.com/nayaks_tutorial",
                    "https://www.instagram.com/nayakstutorialss/",
                    "https://www.youtube.com/channel/UCSEL7iQLhfjOrIoDQWOqbDw"
                    ]`
                    }
                </script>

                <script type="application/ld+json">
                    {
                        `"@context": "https://schema.org/",
                    "@type": "BreadcrumbList",
                    "itemListElement": [{
                        "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://nayakstutorials.com/"
  },{
                        "@type": "ListItem",
                    "position": 2,
                    "name": "CA classes in Thane",
                    "item": "https://nayakstutorials.com/ca/"
  }]`
                    }
                </script>

                <script type="application/ld+json">
                    {
                        `"@context": "https://schema.org/",
                    "@type": "Product",
                    "name": "CA classes in Thane",
                    "image": "https://nayakstutorials.com/static/media/mainImg.2fd512e526d0412ced2e.png",
                    "description": "Nayak's Tutorials in Thane offers CA coaching with expert guidance to help students excel in Chartered Accountancy exams. Admissions open now.",
                    "brand": {
                        "@type": "Brand",
                    "name": "Nayak's Tutorials"
  }`
                    }
                </script>

            </Helmet>
            <LogoContact />
            <Navbar />
            <div className='medicalTopDiv'>
            <div className='breadcrumbNayak' style={{ marginBottom: "10px" }}><a href='/'>Home »</a><a href='/'>Our Offerings »</a><a>College »</a><a>CA & CS</a></div>
                <div className='medicalMain'>
                    <div className='medicalLeftDiv'>
                        <img src={mainImg} alt='Prepare for Chartered Accountancy and Company Secretary Exams' />
                    </div>
                    <div className='medicalRightDiv'>
                        <h3>CA & CS</h3>
                        <p>If you are inclined towards commerce, then chartered accountancy or company secretary are both good options. There are different competitive exams for both and are extremely tough. With Nayak’s Tutorials, you can prepare for them and create a rewarding career for yourself.</p>
                    </div>
                </div>
                <div className='medicalSeconDiv'>
                    <h1>Here are some tips from Nayak’s Tutorials for preparing for CA & CS.</h1>

                    <div className='medicalSeconMainDiv'>
                        <div className='medicalMultipleText'>
                            <div className='medicalMultipleInnerDiv'>
                                <img src={dot} />
                                <h3>Start Early</h3>
                            </div>
                            <p>Avoid last-minute stress and begin early. Create a schedule that allots enough time for each subject</p>
                        </div>
                        <div className='medicalMultipleText'>
                            <div className='medicalMultipleInnerDiv'>
                                <img src={dot} />
                                <h3>Know the exam format</h3>
                            </div>
                            <p>Take time in understanding the kind of questions like multiple choice, essay type, and know the marking scheme as well as the time limits</p>
                        </div>
                        <div className='medicalMultipleText'>
                            <div className='medicalMultipleInnerDiv'>
                                <img src={dot} />
                                <h3>Organize</h3>
                            </div>
                            <p>Keep your study materials, notes, other books close at hand. This will save you time when revising and help you locate important information. Use folders, color-coding or digital notepads to ensure your study material is well structured</p>
                        </div>
                        <div className='medicalMultipleText'>
                            <div className='medicalMultipleInnerDiv'>
                                <img src={dot} />
                                <h3>Practice test papers</h3>
                            </div>
                            <p>Solving questions papers from yesteryears will help you familiarize with the exam pattern and help you your weak points</p>
                        </div>
                        <div className='medicalMultipleText'>
                            <div className='medicalMultipleInnerDiv'>
                                <img src={dot} />
                                <h3>Break it down</h3>
                            </div>
                            <p>We know that the portion is not small. Break it down into smaller sections and topics. And focus on any one at a time</p>
                        </div>
                        <div className='medicalMultipleText'>
                            <div className='medicalMultipleInnerDiv'>
                                <img src={dot} />
                                <h3>Take breaks</h3>
                            </div>
                            <p>Prolonged hours of studying can lead to mental fatigue and reduced productivity. Take short breaks – drink water, stretch, do a quick physical activity</p>
                        </div>
                        <div className='medicalMultipleText'>
                            <div className='medicalMultipleInnerDiv'>
                                <img src={dot} />
                                <h3>Do active learning</h3>
                            </div>
                            <p>Reading or memorizing is not a great method. Instead, summarize in your own words, create diagrams or mind maps or even explain it to someone else</p>
                        </div>
                        <div className='medicalMultipleText'>
                            <div className='medicalMultipleInnerDiv'>
                                <img src={dot} />
                                <h3>Seek clarification</h3>
                            </div>
                            <p>The teachers at Nayak’s Tutorials are always there to clear your doubts or help you with a question. Reach out to us</p>
                        </div>
                        <div className='medicalMultipleText'>
                            <div className='medicalMultipleInnerDiv'>
                                <img src={dot} />
                                <h3>Stay healthy</h3>
                            </div>
                            <p>This is a vital one. You must take care of your physical and mental well-being during exams. Get enough sleep, eat nutritious meals, limit caffeine and maintain a positive mindset</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='medicalLastDiv'>
                <h3 className='medicalFaq'>FAQ's</h3>
                <Accordion allowMultipleExpanded={false} allowZeroExpanded>
                    <AccordionItem >
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Who makes more money a CA or CS?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>

                            On an average, compensation for CA is much higher than that of a CS.
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
                <Accordion allowMultipleExpanded={false} allowZeroExpanded>
                    <AccordionItem >
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Is CS simpler than CA?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>

                            This varies for different students, depending on their aptitude. But most people feel that CS is much easier but in reality CA is more math-oriented and CS more theory-based.

                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
                <Accordion allowMultipleExpanded={false} allowZeroExpanded>
                    <AccordionItem >
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Can you share some mantras to crack CA/CS?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>

                            Time management
                            Manage your study time as well as manage your examination time. How much time you need to devote to each question is something you will have to carefully plan out.
                            Know the syllabus
                            So, you can plan to study and revise all the topics within all subjects.
                            Divide and Revise the topics
                            This is one of the best strategies, as it helps you cover all the topics without feeling like a burden.
                            Study daily
                            Study daily, practice question papers or mock papers and do this within a time limit.
                            Solving Previous Years Papers
                            Mock papers are good to test your knowledge, knowing your speed and also gives you confidence.



                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>

            </div>
            <Footer />
        </>
    )
}
